const enJSON = {
  ENUMS: {
    ASSET_STATE: {
      ShortTerm: 'Short Term',
      LongTerm: 'Long Term'
    },
    CONTRACT_STATUS: {
      Pending: 'Pending',
      Active: 'Active',
      Inactive: 'Inactive',
      Expired: 'Expired',
      Canceled: 'Canceled'
    },
    CONTRACT_PAYMENT_PERIOD: {
      Daily: 'Daily',
      Weekly: 'Weekly',
      Monthly: 'Monthly',
      Quarterly: 'Quarterly',
      SemiAnnually: 'Semi-Annually',
      Annually: 'Annually'
    }
  },
  INVOICES: {
    TITLE: 'Invoices',
    SUB_TITLE: "Here's a list of all your invoices",
    LIST: {
      NO_ITEMS: 'No invoices found.'
    },
    COLUMNS: {
      ID: {
        NAME: 'ID'
      },
      OWNER_NAME: {
        NAME: 'Owner'
      },
      STATUS: {
        NAME: 'Status'
      },
      TOTAL_AMOUNT: {
        NAME: 'Total Amount'
      },
      ACCOUNTED: {
        NAME: 'Accounted'
      },
      CREATED: {
        NAME: 'Created'
      },
      ACTIONS: {
        NAME: 'Actions'
      }
    }
  },
  PAYMENT_VOUCHER_LIST: {
    TITLE: 'Payment Vouchers',
    SUB_TITLE: "Here's a list of all your payment vouchers",
    LIST: {
      NO_ITEMS: 'No payment vouchers found.',
      ACTIONS: {
        VIEW: 'View'
      }
    },
    COLUMNS: {
      ID: {
        NAME: 'ID'
      },
      CUSTOMER_NAME: {
        NAME: 'Customer'
      },
      STATE: {
        NAME: 'State'
      },
      PERIOD: {
        NAME: 'Period'
      },
      CREATED: {
        NAME: 'Created'
      },
      STATUS: {
        NAME: 'Status'
      },
      TOTAL_AMOUNT: {
        NAME: 'Amount'
      },
      ACTIONS: {
        NAME: 'Actions'
      }
    }
  },
  PAYMENT_VOUCHER_VIEW: {
    TITLE: 'Payment Vouchers',
    SUB_TITLE: "Here's a list of all your payment vouchers"
  },
  CONTRACT_LIST: {
    TITLE: 'Contracts',
    SUB_TITLE: "Here's a list of all your contracts",
    COLUMNS: {
      ID: {
        NAME: 'ID'
      },
      ASSET_NAME: {
        NAME: 'Asset Name'
      },
      OWNER_NAME: {
        NAME: 'Owner Name'
      },
      MANAGER_NAME: {
        NAME: 'Manager'
      },
      STATUS: {
        NAME: 'Status',
        OPTIONS: {
          ACTIVE: 'Active',
          PENDING: 'Pending',
          INACTIVE: 'Inactive'
        }
      },
      PAYMENT_PERIOD: {
        NAME: 'Payment Period'
      },
      PAYMENT_AMOUNT: {
        NAME: 'Payment Amount',
        PLACEHOLDER: 'Not Set'
      },
      PROPERTY_VALUE: {
        NAME: 'Property Value',
        PLACEHOLDER: 'Not Set'
      },
      MANAGEMENT_FEE: {
        NAME: 'M. Fee',
        PLACEHOLDER: 'No Fee'
      },
      START_DATE: {
        NAME: 'Start Date'
      },
      END_DATE: {
        NAME: 'End Date'
      },
      ACTIONS: {
        NAME: 'Actions'
      }
    },
    LIST: {
      NO_ITEMS: 'No contracts found.',
      HEADER: {
        ACTIONS: {
          CREATE: 'Create'
        }
      },
      ITEM: {
        ACTIONS: {
          VIEW: 'View'
        }
      }
    },
    GRID: {
      NO_ITEMS: 'No contracts found.'
    }
  },
  CONTRACT_VIEW: {
    TITLE: 'Contract',
    SUB_TITLE: 'Contract Details'
  },
  ASSET_LIST: {
    TITLE: 'Assets',
    SUB_TITLE: "Here's a list of all available assets",
    COLUMNS: {
      ID: {
        NAME: 'ID'
      },
      OWNER: {
        NAME: 'Owner'
      },
      MANAGER: {
        NAME: 'Manager'
      },
      ASSET: {
        NAME: 'Name'
      },
      STATE: {
        NAME: 'State'
      },
      STATUS: {
        NAME: 'Status'
      },
      ACTIONS: {
        NAME: 'Actions'
      }
    },
    LIST: {
      NO_ITEMS: 'No assets found.',
      HEADER: {
        ACTIONS: {
          CREATE: 'Create'
        }
      },
      ITEM: {
        ACTIONS: {
          VIEW: 'View',
          EDIT: 'Edit'
        }
      }
    },
    GRID: {
      NO_ITEMS: 'No assets found.',
      PAGINATION: {
        ITEMS_NAME: 'Assets'
      }
    }
  }
};

export default enJSON;
